import React from 'react';

const IconMedium = () => (
  <svg viewBox="0 0 24 24" fill="none" role="img" xmlns="http://www.w3.org/2000/svg">
    <title>Medium</title>
    <path
      d="M1.5625 1.5625V23.4375H23.4375V1.5625H1.5625ZM19.7363 6.74316L18.5645 7.86621C18.4619 7.94434 18.4131 8.07129 18.4326 8.19336V16.46C18.4131 16.5869 18.4619 16.7139 18.5645 16.7871L19.7119 17.9102V18.1592H13.9502V17.9199L15.1367 16.7676C15.2539 16.6504 15.2539 16.6162 15.2539 16.4404V9.75586L11.9531 18.1348H11.5088L7.66602 9.75586V15.3711C7.63184 15.6055 7.71484 15.8447 7.88086 16.0156L9.42383 17.8857V18.1348H5.03906V17.8857L6.58203 16.0156C6.74805 15.8447 6.82129 15.6055 6.78223 15.3711V8.87695C6.80176 8.69629 6.7334 8.52051 6.59668 8.39844L5.22461 6.74316V6.49414H9.4873L12.7783 13.7207L15.6738 6.49902H19.7363V6.74316Z"
      fill="currentColor"
    />
  </svg>
);

export default IconMedium;
